import apiTool from '@/command/apiTool'
import { Button } from 'ant-design-vue'

function onOpen({ update, records = {} } = {}) {
  apiTool.showDrawer({
    title: '快递配置',
    formData: { ...records },
    width: '600px',
    form: [
      // {
      //   name: '标题',
      //   key: 'deliveryName',
      //   type: 'input'
      // },
      {
        name: 'Banner',
        type: 'iconUpload',
        typeData: [
          {
            key: 'image2',
            desc: '@2x   xhdpi <br/>上传尺寸750*245px'
          },
        ]
      }
    ],
    success: ({ data, setHidden }) => {
      update({ ...records, ...data }).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

export default function() {
  const { update } = arguments[0]
  return {
    url: '/config/farmOrderBackground/list',
    edit: '/config/farmOrderBackground/update',
    del: '/config/farmOrderBackground/updateBatch',
    type: 'table',
    props: {
      showFootButton: false,
      on: {
        handleAddData() {}
      },
      columns: [
        {
          dataIndex: 'orderStatus',
          align: 'left',
          customRender: function customRender(text, records, index, h) {
            const state = ['待支付', '待使用', '待发货', '待签收', '待评价', '已完成', '已关闭'][text]
            return (
              <div>
                {(records.image2 || records.image3) && (
                  <img
                    src={records.image2 || records.image3}
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px'
                    }}
                  />
                )}
                <span>{state}</span>
              </div>
            )
          }
        },
        {
          dataIndex: 'a1',
          width: '200px',
          customRender: function customRender(text, records, index, h) {
            return (
              <Button onClick={() => onOpen({ update, records })} ghost={true} type="primary">
                编辑
              </Button>
            )
          }
        }
      ]
    }
  }
}
